var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vipCenter-bg"},[_c('div',{staticClass:"yx-wid-12 vipCenter"},[(_vm.userInfo)?_c('div',{staticClass:"user-left"},_vm._l((_vm.menus),function(item,index){return _c('div',{key:index,staticClass:"user-nav"},[_c('div',{staticClass:"user-nav-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('ul',{staticClass:"user-nav-ul"},_vm._l((item.list),function(row,index){return _c('li',{key:index,class:{ hover: row.aid === _vm.cwmenusInd },on:{"click":function($event){return _vm.tabMenuCw(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{staticClass:"mr",attrs:{"src":row.icon}}),_c('img',{staticClass:"xz",attrs:{"src":row.iconHover}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(row.name))]),(row.aid === 9 && _vm.message > 0)?_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])}),0)])}),0):_vm._e(),_c('div',{staticClass:"user-right"},[(_vm.userInfo)?_c('div',{staticClass:"user-right-info"},[_c('div',{staticClass:"photo",on:{"click":_vm.setPhoto}},[_c('span',[_vm._v("更改头像")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.userInfo.photoUrl),expression:"userInfo.photoUrl"}]})]),(_vm.accountMoney && _vm.accountMoney.userBalance >= 0)?_c('div',{staticClass:"info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo.userName))]),_c('p',[_vm._v(" 账户余额："),_c('i',{staticClass:"money"},[_vm._v("¥"+_vm._s(_vm.accountMoney.userBalance ? _vm.accountMoney.userBalance.toFixed(2) : "0.00"))])])]):_vm._e(),_c('div',{staticClass:"info-list"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require('@/assets/images/mine/icon/user01' +
                    (_vm.userInfo.realName &&
                    _vm.userInfo.sex >= 0 &&
                    _vm.userInfo.birthDate
                      ? '-1'
                      : '') +
                    '.png')}}),_vm._v(" 基本资料："),(
                  !(
                    _vm.userInfo.realName &&
                    _vm.userInfo.sex >= 0 &&
                    _vm.userInfo.birthDate
                  )
                )?_c('span',{staticClass:"text-blue",on:{"click":function($event){return _vm.jumpInfo('user')}}},[_vm._v("未完善")]):_c('span',[_vm._v("已完善")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/mine/icon/user03.png")}}),_vm._v(" 账户安全等级："),_c('span',[_vm._v(_vm._s(_vm.infoLevel))])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/mine/icon/user04.png")}}),_vm._v(" 密码修改："),_c('span',{staticClass:"text-blue",on:{"click":function($event){return _vm.jumpInfo('pwd')}}},[_vm._v("去修改")])]),_c('li',[_c('img',{attrs:{"src":require('@/assets/images/mine/icon/user02' +
                    (_vm.userBankCardList && _vm.userBankCardList.length
                      ? '-1'
                      : '') +
                    '.png')}}),_vm._v(" 绑定银行卡："),(!(_vm.userBankCardList && _vm.userBankCardList.length))?_c('span',{staticClass:"text-blue",on:{"click":function($event){return _vm.jumpInfo('bank')}}},[_vm._v("去绑定")]):_c('span',[_vm._v("已绑定")])]),_c('li',[_c('img',{attrs:{"src":require('@/assets/images/mine/icon/user05' +
                    (_vm.userInfo.mobile ? '-1' : '') +
                    '.png')}}),_vm._v(" 绑定手机号码："),(!_vm.userInfo.mobile)?_c('span',{staticClass:"text-blue",on:{"click":function($event){return _vm.jumpInfo('phone')}}},[_vm._v("去绑定")]):_c('span',[_vm._v("已绑定")])]),_c('li',[_c('img',{attrs:{"src":require('@/assets/images/mine/icon/user06' +
                    (_vm.userInfo.email ? '-1' : '') +
                    '.png')}}),_vm._v(" 绑定邮箱："),(!_vm.userInfo.email)?_c('span',{staticClass:"text-blue",on:{"click":function($event){return _vm.jumpInfo('mail')}}},[_vm._v("去绑定")]):_c('span',[_vm._v("已绑定")])])])])]):_vm._e(),_c('router-view')],1),(_vm.isExitPhoto && _vm.userInfo && _vm.userInfo.photoUrl)?_c('SetPhoto',{attrs:{"visible":_vm.isExitPhoto,"title":_vm.$t('mine.index.252217-39')},on:{"update:visible":function($event){_vm.isExitPhoto=$event}}}):_vm._e(),(_vm.isHelp)?_c('helpCenter',{attrs:{"visible":_vm.isHelp,"scrollTop":_vm.helpTop},on:{"update:visible":function($event){_vm.isHelp=$event}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }